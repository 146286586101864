import {useEffect, useState, useRef} from "react";
import {listAfdApi} from "../../../apis/user";
import {Avatar, Button, List, Result, Spin} from "antd";
import {connect} from "react-redux";
import {HeartOutlined} from '@ant-design/icons';
import './DonationPanel.css';

function DonationPanel(props){
    const {userInfo} = props;
    const containerRef = useRef(null);

    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [donationData, setDonationData] = useState({})
    const [hasMore, setHasMore] = useState(true)

    useEffect(() => {
        addData(page)
    }, [page]);

    useEffect(() => {
        const handleScroll = () => {
            if (loading || !hasMore) return;
            
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            
            // 当滚动到距离底部100px时加载更多
            if (documentHeight - scrollTop - windowHeight < 100) {
                setPage(prev => prev + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, hasMore]);

    function addData(page){
        setLoading(true)

        listAfdApi({
            page
        }).then(res=>{
            if(res.code === 200){
                setData(prevData => [...prevData, ...res.data.rows])
                setDonationData(res.data)
                // 如果返回的数据少于预期，说明没有更多数据了
                setHasMore(res.data.rows.length > 0)
            }
            setLoading(false)
        }).catch(e=>{
            setLoading(false)
            setHasMore(false)
        })
    }

    return <div className="login-page">
        <div className="background"></div>
        <Result
            status="success"
            icon={<img src="/forum/static/images/icon.png" alt="PocketDestiny Logo"
                       className="card-logo"/>}
            title="感谢所有支持PocketDestiny的粉丝"
            subTitle={<div>
                为PocketDestiny贡献一份力量，成为PocketDestiny捐赠者!
            </div>}
            extra={[
                <a href={"https://afdian.com/a/PocketDestiny"} target="_blank" rel="noopener noreferrer">
                    <Button className="donate-btn">
                        <HeartOutlined />
                        立即捐赠
                    </Button>
                </a>,
                <div className="bg-container">
                    <div className="card">
                        <div>
                            <h2>捐赠者列表 </h2>
                            <span className={"ant-result-subtitle"}>
                                该排名不分先后，感谢以下{donationData.total}位捐赠者
                            </span>
                        </div>
                        <div className="list-container">
                            <List
                                itemLayout="horizontal"
                                dataSource={data}
                                renderItem={(item) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={<Avatar src={item.avatar}/>}
                                            title={<a>{item.name}</a>}
                                        />
                                    </List.Item>
                                )}
                            />
                            {loading && <div className="loading-indicator">加载中...</div>}
                            {!loading && !hasMore && data.length > 0 && (
                                <div className="loading-indicator">已加载全部</div>
                            )}
                        </div>
                    </div>
                </div>
            ]}
        />
    </div>
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.systemInfo.userInfo,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DonationPanel)