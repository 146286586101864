import "./index.css"
import {Button, Card, Col, List, Radio, Row, Spin, Space, Typography, Divider} from "antd";
import {useEffect, useState} from "react";
import {listNewsApi} from "../../apis/news";
import {dateUtcFormat} from "../../utils/TimeUtil";
import {
    NotificationOutlined,
    BulbOutlined,
    GiftOutlined,
    ArrowRightOutlined,
    DownloadOutlined,
    TeamOutlined
} from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

export default function Index() {
    const [newsType, setNewsType] = useState("notice")
    const [newsLoading, setNewsLoading] = useState(false)
    const [newsTableData, setNewsTableData] = useState({
        total: 0,
        rows: [],
    })

    useEffect(() => {
        setNewsLoading(true)
        listNewsApi({
            pageSize: 10,
            page: 1,
            type: newsType
        }).then(res => {
            if(res.code === 200) {
                setNewsTableData(res.data)
            }
        }).finally(() => {
            setNewsLoading(false)
        })
    }, [newsType])

    const onNewsTypeChange = (e) => {
        setNewsType(e.target.value)
    }

    const newsTypeIcons = {
        notice: <NotificationOutlined />,
        news: <BulbOutlined />,
        activity: <GiftOutlined />
    };

    const title = (
        <Row justify="space-between" align="middle" className="news-header">
            <Col>
                <Space size={16}>
                    <Title level={4} className="news-card-title">最新动态</Title>
                </Space>
            </Col>
            <Col>
                <Radio.Group
                    value={newsType}
                    onChange={onNewsTypeChange}
                    className="news-type-selector"
                >
                    <Radio.Button value="notice">
                        <Space>
                            {newsTypeIcons.notice}
                            公告
                        </Space>
                    </Radio.Button>
                    <Radio.Button value="news">
                        <Space>
                            {newsTypeIcons.news}
                            新闻
                        </Space>
                    </Radio.Button>
                    <Radio.Button value="activity">
                        <Space>
                            {newsTypeIcons.activity}
                            活动
                        </Space>
                    </Radio.Button>
                </Radio.Group>
            </Col>
        </Row>
    );

    return (
        <div className="home">
            <div className="background"></div>
            <div className="hero-section">
                <div className="hero-content">
                    <img 
                        src="/forum/static/images/icon.png"
                        alt="PocketDestiny Logo" 
                        className="hero-logo"
                    />
                    <Title className="hero-title">欢迎来到 PocketDestiny</Title>
                    <Paragraph className="hero-description">
                        探索无限可能的口袋世界。收集、训练、对战，成为最强大的训练师！
                    </Paragraph>
                    <Space size="large" className="hero-buttons">
                        <Button type="primary" size="large" href="/download" icon={<DownloadOutlined />}>
                            立即下载
                        </Button>
                        <Button size="large" href="/forum" icon={<TeamOutlined />}>
                            加入社区
                        </Button>
                    </Space>
                </div>
            </div>

            <div className="content-section">
                <Spin spinning={newsLoading}>
                    <Card 
                        title={title} 
                        className="news-card"
                        bordered={false}
                    >
                        <NewsList data={newsTableData.rows} />
                        {newsTableData.total > 10 && (
                            <div className="view-more">
                                <Button 
                                    type="link"
                                    className="view-more-btn"
                                >
                                    查看更多
                                    <ArrowRightOutlined />
                                </Button>
                            </div>
                        )}
                    </Card>
                </Spin>
            </div>
        </div>
    );
}

function NewsList(props) {
    const {data} = props;

    return (
        <List
            itemLayout="horizontal"
            size="small"
            dataSource={data}
            className="news-list"
            renderItem={(item) => (
                <List.Item key={item.id} className="news-item">
                    <a href={item.url} className="news-link">
                        <Row justify="space-between" align="middle" style={{width: '100%'}}>
                            <Col flex="auto">
                                <Text className="news-title" ellipsis>{item.title}</Text>
                            </Col>
                            <Col>
                                <Text className="news-date">{dateUtcFormat(item.createAt, "YYYY-MM-DD")}</Text>
                            </Col>
                        </Row>
                    </a>
                </List.Item>
            )}
        />
    );
}