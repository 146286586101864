import './index.css'
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {Avatar, Badge, Modal, Space} from "antd";
import {BellOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {logoutApi} from "../../../apis/user";
import {changeUserInfoAction} from "../../../redux/action/UserAction";
import {getServerImgPath} from "../../../utils/ImageUtil";
import {getNoticeUnreadCountApi} from "../../../apis/notice";
import {authIsManage, authIsWorkOrder} from "../../../utils/authUtil";


function ForumHeader(props) {
    const {userInfo} = props;

    const [noticeUnreadCount, setNoticeUnreadCount] =  useState(0)

    useEffect(() => {
        if(userInfo?.username){

            getNoticeUnreadCountApi().then(res=>{
                if(res.code === 200){
                    setNoticeUnreadCount(res.data)
                }
            })

        }

    }, [userInfo])

    const navigate = useNavigate();


    // 点击退出登录
    const onLogoutClick = (info) => {
        Modal.confirm({
            title: ["退出登录"],
            icon: <ExclamationCircleOutlined />,
            content: ["你是否确认退出PocketDestiny论坛登录"],
            okText: "确认",
            okType: 'danger',
            cancelText: "取消",
            onOk() {
                logoutApi().then(res=>{
                    changeUserInfoAction({})
                    navigate("/forum/login")
                })
            },
        });
    }

    let isLogin =  window.location.href.includes("/forum/register") ||  window.location.href.includes("/forum/login")

    return <div className="forum-header">
        <nav className="forum-nav">
            <a href="/forum" className="forum-nav-brand">PocketDestiny</a>

            {!isLogin && <div className="nav-right">
                {userInfo?.username && <div style={{cursor: "pointer"}} onClick={() => {
                    navigate("/forum/notice")
                }}>
                    <Badge count={noticeUnreadCount} overflowCount={10} offset={[0, 10]}>
                        <Avatar shape="square" size="large" style={{background: "transparent"}}>
                            <BellOutlined style={{color: "rgba(0, 0, 0, 0.65)", fontSize: 24, transition: "all 0.3s ease"}} className="notice-icon"/>
                        </Avatar>
                    </Badge>
                </div>}

                <div className="user-dropdown">
                    {!userInfo?.username && <>

                        <a href="/forum/login" className={"login-text"}>立即登录</a>
                    </>}
                    {userInfo?.username && <Space>

                        <img src={getServerImgPath(userInfo?.headUrl)} alt="用户头像" className="user-avatar"/>
                        <div className="dropdown-content">
                            <span className="dropdown-item">{userInfo?.nickname}</span>
                            <span className="dropdown-item" onClick={() => navigate("/forum/userInfo")}>个人信息</span>
                            <span className="dropdown-item" onClick={() => navigate("/forum/donationPanel")}>捐赠</span>
                            <span className="dropdown-item" onClick={() => navigate("/forum/post")}>我的帖子</span>
                            <span className="dropdown-item" onClick={() => navigate("/forum/workOrder")}>我的工单</span>
                            {authIsWorkOrder() && <span className="dropdown-item" onClick={() => navigate("/forum/workOrder/examine")}>待处理工单</span>}
                            <span className="dropdown-item" onClick={() => navigate("/forum/mod")}>我的MOD</span>
                            <span className="dropdown-item" onClick={onLogoutClick}>退出登录</span>
                        </div>
                    </Space>}
                </div>
            </div>}


        </nav>
    </div>
}


const mapStateToProps = (state) => {
    return {
        userInfo: state.systemInfo.userInfo,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ForumHeader)