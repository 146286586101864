import {Col, Menu, Row, Avatar, Space, Drawer} from "antd";
import MenuItem from "antd/es/menu/MenuItem";

import './index.css'
import {useNavigate} from "react-router-dom";
import {
    MenuOutlined,
    HomeOutlined,
    DownloadOutlined,
    CommentOutlined
} from "@ant-design/icons";
import { useState } from 'react';



export default function Header() {
    const navigate = useNavigate();
    const [drawerVisible, setDrawerVisible] = useState(false);

    const onMenuClick = (info) => {
        navigate(info.key);
        setDrawerVisible(false);
    }

    return (
        <div className="destiny-header">
            <nav className="nav">
                <Row justify="space-between" align="middle" className="header-row">
                    <Col>
                        <Space size={16}>
                            <Avatar 
                                src="/forum/static/images/icon.png"
                                size={36}
                                className="header-logo"
                            />
                            <a href="/" className="header-brand">PocketDestiny</a>
                        </Space>
                    </Col>
                    <Col flex="auto" className="desktop-menu">
                        <Menu
                            className="header-menu"
                            mode="horizontal"
                            onClick={onMenuClick}
                        >
                            <MenuItem key="/" icon={<HomeOutlined />}>首页</MenuItem>
                            <MenuItem key="/download" icon={<DownloadOutlined />}>下载</MenuItem>
                            <MenuItem key="/forum" icon={<CommentOutlined />}>论坛</MenuItem>
                        </Menu>
                    </Col>
                    <Col className="mobile-menu">
                        <div 
                            className="menu-trigger"
                            onClick={() => setDrawerVisible(true)}
                        >
                            <MenuOutlined />
                        </div>
                    </Col>
                </Row>
            </nav>

            <Drawer
                title="导航菜单"
                placement="right"
                closable={true}
                onClose={() => setDrawerVisible(false)}
                visible={drawerVisible}
                className="mobile-drawer"
                width={280}
                destroyOnClose={true}
                closeIcon={<MenuOutlined style={{ fontSize: '16px' }} />}
                maskStyle={{ background: 'rgba(0, 0, 0, 0.2)' }}
            >
                <div className="drawer-content">
                    <Menu
                        mode="vertical"
                        onClick={onMenuClick}
                        selectedKeys={[window.location.pathname]}
                    >
                        <MenuItem key="/" icon={<HomeOutlined />}>首页</MenuItem>
                        <MenuItem key="/download" icon={<DownloadOutlined />}>下载</MenuItem>
                        <MenuItem key="/forum" icon={<CommentOutlined />}>论坛</MenuItem>
                    </Menu>
                    
                    <div className="drawer-footer">
                        <div className="footer-logo">
                            <Avatar 
                                src="/forum/static/images/icon.png"
                                size={48}
                                className="footer-avatar"
                            />
                            <div className="footer-text">
                                <div className="footer-title">PocketDestiny</div>
                                <div className="footer-subtitle">口袋命运</div>
                            </div>
                        </div>
                        <div className="footer-info">
                            <div className="info-item">当前版本：1.0.0</div>
                            <div className="info-item">© 2024 PocketDestiny</div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}