import './index.css'
import {Button, Input, Modal, Pagination, Space, Spin} from "antd";
import {useEffect, useState} from "react";
import {delPostFavoritesApi, listFavoritesApi} from "../../../apis/favorites";
import {dateUtcFormat} from "../../../utils/TimeUtil";
import {postStatisticsApi} from "../../../apis/post";
import {connect} from "react-redux";
import {getServerImgPath} from "../../../utils/ImageUtil";
import {DeleteOutlined, ExclamationCircleOutlined, EditOutlined} from "@ant-design/icons";
import {success_notice} from "../../../utils/noticeUtils";
import {useNavigate} from "react-router-dom";

let preSearchText = ""


const sexMap = {
    "male": "男",
    "female": "女",
    "other": "其他",
}
function UserInfo(props) {
    const {userInfo} = props
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState("")

    const [tableData, setTableData] = useState({
        total: 0,
        page: 1,
        pageSize: 10,
        rows: []
    })

    const [data, setData] = useState({
        count: 0,
        view: 0,
        like: 0,
        star: 0,
    })


    useEffect(()=>{
        if(userInfo.id){
            postStatisticsApi(userInfo.id).then(res => {
                if (res.code === 200) {
                    setData(res.data)
                }
            })

            onSearch("")
        }

    }, [userInfo.id])


    const del = (favorites)=>{
        Modal.confirm({
            title: ["删除收藏帖子"],
            icon: <ExclamationCircleOutlined />,
            content: [`你是否确认删除收藏帖子 “${favorites.title}” `],
            okText: "确认",
            okType: 'danger',
            cancelText: "取消",
            onOk() {
                delPostFavoritesApi(favorites.id).then(res=>{
                    if(res.code===200){
                        success_notice("删除成功!")
                        onSearch(preSearchText)
                    }
                })

            },
        });

    }

    const onSearch = (searchText="", page=1) => {
        preSearchText = searchText;
        setLoading(true);
        listFavoritesApi({
            searchText,
            page: page,
            pageSize: 10,
        }).then(res=>{
            if(res.code===200){
                setTableData(res.data)
            }
            setLoading(false);
        }).catch(e=>{
            setLoading(false)
        })
    }

    return <div className="profile-container">
        <div className="profile-main">
            <a href="/forum/user/update">
                <button className="edit-profile-btn">
                    <EditOutlined />
                    编辑资料
                </button>
            </a>
            <div className="profile-header">
                <div className="profile-avatar-container">
                    <img src={getServerImgPath(userInfo?.headUrl)} alt="用户头像" className="profile-avatar"/>
                    <div className="profile-tags">
                        {
                            userInfo?.tags && <>
                            {userInfo.tags.split(",").map(tag=> {
                                return <span className="profile-tag">{tag}</span>
                            })}
                            </>
                        }
                    </div>
                </div>
                <div className="profile-info">
                    <h1>{userInfo?.nickname}</h1>
                    <p>性别：{sexMap[userInfo?.sex || "other"] || sexMap["other"]} </p>
                    <p>加入时间：{dateUtcFormat(userInfo?.createAt, "YYYY-MM-DD")}</p>
                    <p>捐赠点：{userInfo?.donationCoin}</p>
                    <div className="profile-bio">
                        {userInfo.profile}
                    </div>
                </div>
            </div>

            <div className="profile-stats">
                <div className="stat-item">
                    <div className="stat-value">{data.count}</div>
                    <div className="stat-label">发帖数</div>
                </div>
                <div className="stat-item">
                    <div className="stat-value">{data.view}</div>
                    <div className="stat-label">浏览量</div>
                </div>
                <div className="stat-item">
                    <div className="stat-value">{data.like}</div>
                    <div className="stat-label">获赞数</div>
                </div>
                <div className="stat-item">
                    <div className="stat-value">{data.star}</div>
                    <div className="stat-label">收藏数</div>
                </div>
            </div>

            <div className="profile-section">
                <h2>我的收藏</h2>

                <Spin spinning={loading}>
                    <div style={{marginBottom: 8}}>
                        <Input.Search value={searchText} onChange={(val) => {
                            setSearchText(val.target.value)
                        }} onSearch={() => {
                            onSearch(searchText)
                        }} placeholder="搜索帖子..."/>
                    </div>
                    <ul className="favorite-posts">
                        {tableData.rows.map(favorites => {
                            return <li className="favorite-item" key={favorites.id}>
                                <div className="favorite-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2z"/>
                                    </svg>
                                </div>
                                <a className="favorite-content" onClick={()=>{navigate(`/forum/post/details/${favorites.postId}`)}}>

                                    <div className="favorite-title">{favorites.title}</div>

                                    <div className="favorite-info">作者：{favorites.userInfo?.nickname} |
                                        收藏时间：{dateUtcFormat(favorites.createAt)}</div>
                                </a>
                                <div>
                                    <Button icon={<DeleteOutlined />} shape="circle" onClick={()=>{
                                        del(favorites)
                                    }}></Button>
                                </div>
                            </li>
                        })}

                    </ul>
                    <div className="pagination">
                        <div className="pagination-info" style={{color: "#7F8C8DFF"}}>共 {tableData.total} 条收藏</div>
                        <div className="pagination-controls">
                            <Pagination size="small" current={tableData?.page || 1}
                                        pageSize={tableData?.pageSize || 10} total={tableData?.total}
                                        onChange={(page) => {
                                            onSearch(preSearchText, page)
                                        }}
                            />
                        </div>
                    </div>
                </Spin>
            </div>
        </div>

        {/*<div className="profile-sidebar">*/}
        {/*    <div className="profile-section">*/}
        {/*        <h2>最近动态</h2>*/}
        {/*        <ul className="recent-activity">*/}
        {/*            <li className="activity-item">*/}
        {/*                <div className="activity-icon">*/}
        {/*                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">*/}
        {/*                        <path*/}
        {/*                            d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z"/>*/}
        {/*                    </svg>*/}
        {/*                </div>*/}
        {/*                <div className="activity-content">*/}
        {/*                    <div className="activity-title">发布了新帖：自定义UI设计技巧</div>*/}
        {/*                    <div className="activity-time">10分钟前</div>*/}
        {/*                </div>*/}
        {/*            </li>*/}
        {/*            <li className="activity-item">*/}
        {/*                <div className="activity-icon">*/}
        {/*                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">*/}
        {/*                        <path*/}
        {/*                            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>*/}
        {/*                    </svg>*/}
        {/*                </div>*/}
        {/*                <div className="activity-content">*/}
        {/*                    <div className="activity-title">你的帖子"PVP技巧分享"获得了15个赞</div>*/}
        {/*                    <div className="activity-time">30分钟前</div>*/}
        {/*                </div>*/}
        {/*            </li>*/}
        {/*            <li className="activity-item">*/}
        {/*                <div className="activity-icon">*/}
        {/*                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">*/}
        {/*                        <path*/}
        {/*                            d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 9h-2V5h2v6zm0 4h-2v-2h2v2z"/>*/}
        {/*                    </svg>*/}
        {/*                </div>*/}
        {/*                <div className="activity-content">*/}
        {/*                    <div className="activity-title">回复了帖子：关于新版本平衡性的讨论</div>*/}
        {/*                    <div className="activity-time">2小时前</div>*/}
        {/*                </div>*/}
        {/*            </li>*/}
        {/*        </ul>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </div>

}

const mapStateToProps = (state) => {
    return {
        userInfo: state.systemInfo.userInfo,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo)