/**
 * 游戏下载页面组件
 * 展示游戏下载信息、系列模组和相关下载内容
 */
import './index.css'
import {useEffect, useState} from "react";
import {listAreaModApi, listSeriesModApi} from "../../apis/mod";
import {Button, Card, Divider, List, Space, Tabs, Typography, Tag, Statistic, Row, Col, Spin} from "antd";
import {getAppInfoApi} from "../../apis/app";
import {
    AndroidOutlined,
    DownloadOutlined,
    ClockCircleOutlined,
    CloudDownloadOutlined,
    FileTextOutlined
} from '@ant-design/icons';

const {Title, Text, Paragraph} = Typography;

export default function Download(){
    // 状态管理
    const [series, setSeries] = useState([])  // 存储系列模组数据
    const [appInfo, setAppInfo] = useState({}) // 存储游戏基本信息
    const [loading, setLoading] = useState(true) // 加载状态控制

    // 获取游戏基本信息
    useEffect(()=>{
        setLoading(true)
        getAppInfoApi().then(res=>{
            if(res.code === 200){
                setAppInfo(res.data)
            }
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    // 获取系列模组数据
    useEffect(()=>{
        setLoading(true)
        queryMod({
            page:1,
            pageSize: 100,
        })
    }, [])

    // 查询模组数据的方法
    const queryMod = (params) =>{
        listSeriesModApi(params).then(res=>{
            if(res.code === 200){
                setSeries(res.data.records)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div className="download-section">
            <div className="download-content">
                <Spin spinning={loading} tip="加载中...">
                    <Card bordered={false}>
                        {/* 标题区域 */}
                        <Title level={2} style={{color: '#333', textAlign: 'center', marginBottom: '24px', fontWeight: 500}}>
                            下载 PocketDestiny
                        </Title>
                        
                        {/* 游戏信息展示区域 */}
                        <div className="game-info">
                            <div style={{padding: "0 50px"}}>
                                <img src="/forum/static/images/icon.png"
                                     alt="PocketDestiny Logo" 
                                     className="game-logo"/>
                                <div className="game-tags" style={{marginTop: '16px', textAlign: 'center'}}>
                                    <Tag color="blue" icon={<AndroidOutlined />}>安卓版本</Tag>
                                    <Tag color="green" icon={<CloudDownloadOutlined />}>免费下载</Tag>
                                </div>
                            </div>

                            <div className="game-details">
                                <Space direction="vertical" size="large" style={{width: '100%'}}>
                                    <div>
                                        <Title level={4} style={{marginBottom: '16px', color: '#333'}}>
                                            游戏信息
                                        </Title>
                                        <Row gutter={[24, 16]}>
                                            <Col span={12}>
                                                <Statistic 
                                                    title="当前版本" 
                                                    value={appInfo?.version} 
                                                    prefix={<FileTextOutlined />}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <Statistic 
                                                    title="更新日期" 
                                                    value={appInfo?.createAt} 
                                                    prefix={<ClockCircleOutlined />}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    
                                    <div>
                                        <Title level={4} style={{marginBottom: '8px', color: '#333'}}>
                                            更新说明
                                        </Title>
                                        <div className="update-notes">
                                            <Paragraph style={{color: '#666', fontSize: '14px'}}>
                                                {appInfo?.remark}
                                            </Paragraph>
                                        </div>
                                    </div>
                                </Space>
                            </div>
                        </div>

                        {/* 主下载按钮 */}
                        <div className="download-options">
                            <Button 
                                type="primary" 
                                icon={<AndroidOutlined />} 
                                size="large"
                                href={appInfo?.downloadUrl}
                                className="download-btn">
                                下载游戏
                            </Button>
                        </div>

                        <Divider style={{margin: "24px 0", borderColor: 'rgba(0,0,0,0.06)'}}/>

                        <div className="mod-section">
                            <Title level={3} style={{color: '#333', marginBottom: '16px', fontWeight: 500}}>
                                Mod
                            </Title>
                            <div className="mod-tip">
                                <div className="tip-content">
                                    <Text style={{color: '#666', fontSize: '14px'}}>
                                        温馨提示：游戏模组可选择下载，如果游戏内无法下载模组包，可以在这里手动下载后导入游戏
                                    </Text>
                                </div>
                            </div>
                        </div>

                        <Tabs 
                            type="card"
                            className="custom-tabs">
                            {series.map((item) => (
                                <Tabs.TabPane tab={item.name} key={item.name}>
                                    <div style={{padding: "1rem"}}>
                                        {/* 系列描述卡片 */}
                                        <Card 
                                            bordered={false} 
                                            className="series-card">
                                            <Paragraph style={{color: '#666', fontSize: '14px'}}>
                                                {item.remark}
                                            </Paragraph>
                                            <div className="download-options">
                                                <Button 
                                                    type="primary"
                                                    icon={<DownloadOutlined />}
                                                    href={item.host+item?.download}
                                                    className="download-btn">
                                                    下载
                                                </Button>
                                            </div>
                                        </Card>

                                        <Divider style={{margin: "16px 0", borderColor: 'rgba(0,0,0,0.06)'}}/>
                                        {/* 渲染该系列下的具体模组列表 */}
                                        <Mods seriesData={item}/>
                                    </div>
                                </Tabs.TabPane>
                            ))}
                        </Tabs>
                    </Card>
                </Spin>
            </div>
        </div>
    );
}

/**
 * 模组列表组件
 * @param {Object} props.seriesData - 当前系列的数据
 * 展示指定系列下的所有可用模组
 */
function Mods(props){
    const {seriesData} = props;
    const [data, setData] = useState([]) // 存储模组列表数据
    const [loading, setLoading] = useState(false) // 加载状态控制

    // 获取模组列表数据
    useEffect(() => {
        setLoading(true)
        listAreaModApi({
            page:1,
            pageSize: 100,
        }).then(res=>{
            if(res.code===200){
                // 过滤出当前系列的模组，且状态为可用或待开放
                let list = res.data.records
                    .filter(record => 
                        record.category === seriesData?.category && 
                        (record.status === 1 || record.status === 2)
                    );
                setData(list)
            }
        }).finally(() => {
            setLoading(false)
        })
    }, [seriesData?.category]);

    return <Spin spinning={loading} tip="加载中...">
        <List
            itemLayout="horizontal"
            size="small"
            className="mod-list"
            dataSource={data}
            renderItem={(item) => (
                <List.Item key={item.id} className="news-item">
                    <Space size="middle" style={{width: '100%', justifyContent: 'space-between'}}>
                        <Text strong style={{color: '#333', fontSize: '14px'}}>{item.name}</Text>
                        {/* 根据模组状态显示下载按钮或待开放文本 */}
                        {item.status === 1 ? (
                            <Button 
                                type="primary" 
                                icon={<DownloadOutlined />}
                                href={item.host+item?.download}>
                                下载
                            </Button>
                        ) : (
                            <Text type="secondary" style={{fontSize: '14px'}}>待开放</Text>
                        )}
                    </Space>
                </List.Item>
            )}
        />
    </Spin>
}